import { useNavigate } from 'react-router-dom';
import './MainContent.scss';
import Button from '../../components/button/Button';
import Floater from '../../components/floater/floater';
import WhyUs from '../WhyUs/WhyUs';
import CallToAction from '../../components/CTA/CTA';
import NavHeader from '../../components/NavHeader/NavHeader';
import three from '../../../images/3logo.png';
import gh from '../../../images/github.png';
import npm from '../../../images/npm.png';
import netlify from '../../../images/netlify.png';
import react from '../../../images/react.png';
import git from '../../../images/git.png';
import vs from '../../../images/vscode.png';
import m from '../../../images/3m.png';
import apple from '../../../images/apple.png';
import dnb from '../../../images/dnb.png';
import g from '../../../images/g.png';
import target from '../../../images/t.png';
import u from '../../../images/uni.png';
import wgr from '../../../images/wgr.png';
import up from '../../../images/upshop.png';
import gen from '../../../images/generac.png';

const MainContent = ({ randomColor, changeColor, journeyImage }) => {
  const navigate = useNavigate();

  const enrollNow = () => {
    navigate('/services');
  };

  return (
    <div className='content-section'>
      <div className='row'>
        <div
          className='ru-column'
          style={{
            backgroundColor: randomColor,
          }}
        >
          <div>
            <h1 className='ru-header'>Rolonium University</h1>
            <p className='tag-line'>
              Learn to Code. Acquire Real Technical Skills.
            </p>
          </div>
        </div>
        <div
          className='black-column'
          style={{
            color: randomColor,
          }}
        >
          <div className='text-center'>
            <h1>Get Started</h1>
            <div>
              <Button
                text='Enroll Now'
                txtColor='black'
                backgroundColor={randomColor}
                onClick={enrollNow}
              />
              <Button
                text='Learn More'
                txtColor='black'
                backgroundColor={'#f2f2f2'}
                onClick={enrollNow}
              />
            </div>
          </div>
          <p className='click-me' onClick={changeColor}>
            Click me for a surprise
          </p>
        </div>
      </div>

      <NavHeader />

      <WhyUs color={randomColor} />

      <CallToAction color={randomColor} showTutorialBtn={true} />

      <div className='content'>
        <div className='text-center'>
          <h1>
            Here we Transform Students into{' '}
            <span
              style={{
                borderRadius: '6px',
                padding: '15px',
                backgroundColor: randomColor,
                fontWeight: 900,
                transitionDuration: '0.4s',
              }}
            >
              Elite
            </span>{' '}
            Front-End Developers
          </h1>
          <p>
            Take a look at some of the technologies your professor will
            introduce you to. These technologies are used by almost all
            companies who build software.
          </p>
        </div>
        <div className='logos-container'>
          <div>
            <img src={three} alt='logo' />
            <img
              src={`https://www.cdnlogo.com/logos/a/35/angular.svg`}
              alt='logo'
            />
            <img src={react} alt='logo' />
          </div>

          <div>
            <img src={npm} alt='logo' />
            <img src={gh} alt='logo' />
            <img src={git} alt='logo' />
          </div>

          <div>
            <img src={vs} alt='logo' />
            <img src={netlify} alt='logo' />
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-center'>
          <h1>
            Your Professor has{' '}
            <span
              style={{
                borderRadius: '6px',
                padding: '15px',
                backgroundColor: randomColor,
                fontWeight: 900,
                transitionDuration: '0.4s',
              }}
            >
              10 years
            </span>{' '}
            using the above technologies in fortune 500 companies.
          </h1>
          <p>
            These companies provide services to generate over{' '}
            <span
              style={{
                borderBottom: '10px solid',
                borderBottomColor: randomColor,
              }}
            >
              $1 Billion dollars
            </span>{' '}
            in revenue. He has written code for each of these companies you see
            listed below either in business applications they use or directly
            for their own services they sell.
          </p>
        </div>
        <div className='logos-container'>
          <div>
            <img src={up} alt='logo' />
            <img src={gen} alt='logo' />
            <img src={wgr} alt='logo' />
          </div>

          <div>
            <img src={g} alt='logo' />
            <img src={target} alt='logo' />
            <img src={u} alt='logo' />
          </div>

          <div>
            <img src={dnb} alt='logo' />
            <img style={{ width: '100px' }} src={m} alt='logo' />
            <img style={{ width: '100px' }} src={apple} alt='logo' />
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-center'>
          <h1>
            Your{' '}
            <span
              style={{
                borderRadius: '6px',
                padding: '15px',
                backgroundColor: randomColor,
                fontWeight: 900,
                transitionDuration: '0.4s',
              }}
            >
              Journey
            </span>{' '}
            throughout RoloniumUniversity.
          </h1>
          <p>
            This proven journey will{' '}
            <span
              style={{
                borderBottom: '10px solid',
                borderBottomColor: randomColor,
              }}
            >
              prepare
            </span>{' '}
            you for the real world and landing you, your very first engineering
            job.
          </p>
        </div>
        <img
          src={journeyImage}
          alt='logo'
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>

      <CallToAction color={randomColor} />
      <Floater />
    </div>
  );
};

export default MainContent;
