import './floater.css';
import { useNavigate } from 'react-router-dom';

const Floater = () => {
  const navigate = useNavigate();

  const enrollNow = () => {
    navigate('/services');
  };

  return (
    <div
      className='all-access-pass'
      id='aap-design'
      data-aap-type='base'
      data-aap-level='1'
      data-aap-kf='{"start": "a0t - 100vh + 40a0h", "end": "a0b - 50vh", "anchors": [".subsection-design"]}'
      data-focus-expression='{"expression": "a0b - 80vh", "anchors": [".subsection-design"]}'
      data-focus-enabled-when='enhanced'
    >
      <div className='all-access-pass__background'>
        <button
          className='aap-base'
          data-modal-open=''
          data-analytics-title='expand - design and display modal'
          data-analytics-click='prop3:expand - design and display modal'
          data-analytics-intrapage-link=''
          onClick={enrollNow}
        >
          <span className='aap-base__text'>
            Enrollment &amp; Course Selection
          </span>
          <span className='aap-base__icon'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
              <path
                id='_art'
                className='st1'
                d='M25.5,16.5l-5.9,0l0-5.9C19.6,9.7,18.9,9,18,9c-0.9,0-1.5,0.7-1.5,1.5l0,5.9l-5.9,0h0
                  	                              C9.7,16.4,9,17.1,9,18c0,0.9,0.7,1.5,1.5,1.5l5.9,0l0,5.9c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5l0-5.9l5.9,0h0
                  	                              c0.9,0,1.5-0.7,1.5-1.5C27,17.2,26.3,16.5,25.5,16.5L25.5,16.5z'
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Floater;
