import './CTA.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CTA = ({ color, showTutorialBtn }) => {
  const [student, setStudent] = useState({
    Name: '',
    CellPhone: '',
    Message: '',
  });

  const [showTutorial, setShowTutorial] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const codeSnippet = `

  const [student, setStudent] = useState({ name: '', number: '', message: '' });
  ...
`;

  const codeInput = `
    <input
      placeholder="Student's Full Name"
      type='text'
      name='name'
      value={student.name}
      onChange={handleInputChange}
    ></input>
    ...
`;

  const buttonSnipet = `      <Button
        text='Start Your Journey!'
        backgroundColor={color}
        onClick={sendMessage}
      />
      ...`;

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'CellPhone' && value.length > 10) {
      alert('Enter a valid Phone Number.');
      return;
    }

    setStudent((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const showTutorialClicked = () => {
    setShowTutorial(!showTutorial);
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    if (student.CellPhone.length < 10 || student.Name.length < 3) {
      return;
    }
    const res = await fetch(
      'https://yourhope.io/api/Notifications/POSTFSClientMessage',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(student),
      }
    );
    if (res.status === 200) {
      setShowVideo(true);
    }
  };

  return (
    <div className='cta'>
      {!showVideo ? (
        <div>
          <h1 className='bouncing-container'>
            Are you ready to{' '}
            <span className='bouncing-span' style={{ backgroundColor: color }}>
              {' '}
              Level Up
            </span>
            your life?
          </h1>
          <p className='sub-header'>
            Connect with us and get the Web dev prerequiste PDF for FREE & stay
            up to date with Rolonium University.
          </p>
        </div>
      ) : (
        <div>
          <h1 className='bouncing-container'>
            <span className='bouncing-span'>🎉</span>
            Taking your first step in your career in Software Development!
            <span className='bouncing-span'>🎊</span>
          </h1>
          <p class='sub-header'>
            Here's a short thank you from your Professor, James.
          </p>
        </div>
      )}
      <div>
        {!showVideo && (
          <div>
            <div className='subscribe-section'>
              <div className='contact-details'>
                <input
                  placeholder="Student's Full Name"
                  type='text'
                  name='Name'
                  value={student.Name}
                  onChange={handleInputChange}
                ></input>
                <input
                  placeholder="Student's Mobile Phone Number"
                  type='number'
                  name='CellPhone'
                  value={student.CellPhone}
                  maxLength={10}
                  onChange={handleInputChange}
                ></input>
              </div>
              <textarea
                placeholder='Write us a brief message on what you want to accomplish, desires, and your questions for us...'
                className='message-box'
                rows='10'
                name='Message'
                value={student.Message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className='send-message-btn'>
              <Button
                text='Start Your Journey!'
                backgroundColor={color}
                onClick={sendMessage}
              />
            </div>
          </div>
        )}
        {showVideo && (
          <div className='center'>
            <iframe
              width='760'
              height='515'
              style={{ borderRadius: '15px' }}
              src='https://www.youtube.com/embed/Wm2J2qnSsZc?si=Eqp4OFJ2C3CXtL5C&autoplay=1'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            ></iframe>
          </div>
        )}
        <p
          onClick={showTutorialClicked}
          style={{
            borderBottom: `10px solid ${color}`,
            display: 'inline-block',
            cursor: 'pointer',
            paddingBottom: '10px',
          }}
        >
          {showTutorialBtn &&
            (!showTutorial
              ? 'Click me to learn something cool'
              : 'Congratulations you unlocked a tutorial! p.s click me again to hide the tutorial!')}
        </p>

        {showTutorial && (
          <div>
            <p className='badge' style={{ backgroundColor: '#fff0b3' }}>
              Quick Tip
            </p>
            <h2>👋 Your Personal Software Engineer Professor James here.</h2>
            <p>
              Firstly, I would like to thank you for filling out the form &
              congratulations on taking your very first step to learning an
              extermely powerful skill. Now, I want to walk you through at a
              high level on how the form you just filled out works.{' '}
            </p>
            <p>
              Behind the scene's we created a student object, which looks like
              this
            </p>
            <SyntaxHighlighter language='javascript' style={prism}>
              {codeSnippet}
            </SyntaxHighlighter>
            <p>
              We then created our inputs; which capture user inputs, below is an
              example of one
            </p>

            <SyntaxHighlighter language='html' style={prism}>
              {codeInput}
            </SyntaxHighlighter>

            <p>
              The button you clicked on to submit your request looks like this -
              this is a reusable component we created. Fun Fact: all of our
              buttons on this web app uses this exact button component.
            </p>

            <SyntaxHighlighter language='html' style={prism}>
              {buttonSnipet}
            </SyntaxHighlighter>
            <h2>🎉Congratulations, you just read through some code!</h2>
            <p>
              You did it! You completed this small tutorial. You are getting the
              hang of it! If you haven't already. I personally invite you to
              join our university so you can learn exactly how to build
              enterprise level applications!
            </p>
            <Button text='Close Tutorial' onClick={showTutorialClicked} />
          </div>
        )}
      </div>
      {/* <div className='column center'>browse our collection</div> */}
    </div>
  );
};

CTA.defaultProps = {
  color: 'steelblue',
  className: '',
  showTutorialBtn: false,
};

CTA.propTypes = {
  color: PropTypes.string,
};

export default CTA;
