// import { useState } from 'react';
// import { Link } from 'react-router-dom';
import './alertBanner.css';
// import { useLocation, useHistory } from 'react-router-dom';
// import Button from '../button/Button';

const AlertBanner = () => {
  return (
    <div className='block'>
      <div>
        <h2>⏰ Enroll Now!</h2>
        <p>
          Time is running out! Don't miss your chance to change your life and
          become a web development expert! Enroll now in our limited-time web
          development course and unlock the secrets to obtaining a $45K dollar
          job in 6 months. Beat the deadline and secure your spot today for a
          brighter future in the world! 🚀🌐
        </p>
      </div>
    </div>
  );
};

export default AlertBanner;
