import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import AlertBanner from './app/components/AlertBanner/AlertBanner';
import MainContent from './app/pages/MainContent/MainContent';
import CallToAction from './app/components/CTA/CTA';
import Apps from './app/pages/apps/apps';
import MortgageCalculator from './app/components/MortgageCalc/mortgage-calc';
import Footer from './app/components/footer/Footer';
import Services from './app/pages/Services/Services';
import './App.css';
import tlMobile from './images/tlshort.png';
import tlDesktop from './images/timeline.png';

function App() {
  const [imageSrc, setImageSrc] = useState('');
  const [colors] = useState([
    '#5BC0F8',
    '#E8D5C4',
    '#7C9D96',
    '#e1f2fb',
    '#66D19E',
    '#FFF2D9',
    '#4682A9',
    '#91C8E4',
    '#F6F4EB',
  ]);

  const [randomColor, setRandomColor] = useState(
    colors[Math.floor(Math.random() * colors.length)]
  );

  const changeColor = useCallback(() => {
    setRandomColor(colors[Math.floor(Math.random() * colors.length)]);
  }, [colors]);

  useEffect(() => {
    const intervalId = setInterval(changeColor, 15000);

    const updateImageSrc = () => {
      const isMobile = window.innerWidth <= 808; // Define your screen size threshold
      const newImageSrc = isMobile ? tlMobile : tlDesktop;

      setImageSrc(newImageSrc);
    };

    // Initial call to set the image source based on the initial screen size
    updateImageSrc();

    // Listen to window resize events and update the image source accordingly
    window.addEventListener('resize', updateImageSrc);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', updateImageSrc);
    };
  }, [changeColor]);

  return (
    <Router>
      <AlertBanner />
      <div>
        <Routes>
          {/* <Route path='/' exact render={(props) => <>{<MainContent />}</>} /> */}

          <Route
            path='/'
            element={
              <MainContent
                randomColor={randomColor}
                changeColor={changeColor}
                journeyImage={imageSrc}
              />
            }
          />
          <Route
            path='/contact'
            element={<CallToAction randomColor={randomColor} />}
          />
          <Route
            path='/services'
            element={
              <Services randomColor={randomColor} journeyImage={imageSrc} />
            }
          />
          <Route path='/apps' element={<Apps randomColor={randomColor} />} />
          <Route
            path='/mcalc'
            element={<MortgageCalculator randomColor={randomColor} />}
          />
          <Route
            path='*'
            element={
              <MainContent
                randomColor={randomColor}
                changeColor={changeColor}
              />
            }
          />
          {/* <MainContent /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
