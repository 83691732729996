import React, { useState } from 'react';
import Button from '../button/Button';
import './calc.scss';
import { Link } from 'react-router-dom';
import NavHeader from '../../components/NavHeader/NavHeader';

const MortgageCalculator = ({ randomColor }) => {
  const [salesPrice, setSalesPrice] = useState(0);
  const [downPaymentAmount, setDownPaymentAmount] = useState(0); // State to hold the down payment amount
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(0); // Set a default down payment percentage
  const [principal, setPrincipal] = useState(0);
  const [annualInterestRate, setAnnualInterestRate] = useState(5.99);
  const [loanTerm, setLoanTerm] = useState(30);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const calculateMonthlyPayment = () => {
    // Calculate the down payment amount based on the sales price and down payment percentage
    const downPaymentAmount = (salesPrice * downPaymentPercentage) / 100;

    // Calculate the principal by subtracting the down payment from the sales price
    const principal = salesPrice - downPaymentAmount;

    // The rest of the mortgage calculation remains the same
    const monthlyInterestRate = annualInterestRate / 12 / 100; // Monthly interest rate
    const loanTermMonths = loanTerm * 12; // Total number of payments in months

    const monthlyPayment =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));

    setDownPaymentAmount(downPaymentAmount);
    setPrincipal(principal);
    setMonthlyPayment(monthlyPayment.toFixed(2));
  };

  const handleDownPaymentPercentageChange = (e) => {
    // Update the down payment percentage and calculate the corresponding down payment amount
    const percentage = parseFloat(e.target.value);
    setDownPaymentPercentage(percentage);
    const downPaymentAmount = (salesPrice * percentage) / 100;
    setDownPaymentAmount(downPaymentAmount);
  };

  const formatAsCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  return (
    <div>
      <NavHeader></NavHeader>
      <Link to='/apps'>
        <p> ⬅️ back to apps</p>
      </Link>
      <div className='mortgage-calculator'>
        <h1>
          Mortgage Calculator
          <span
            style={{
              color: 'rgb(124, 157, 150)',
            }}
          >
            .
          </span>
        </h1>
        <div className='input-group'>
          <label>Sales Price:</label>
          <input
            type='number'
            value={salesPrice}
            onChange={(e) => setSalesPrice(e.target.value)}
          />
        </div>
        <div className='input-group'>
          <label>Down Payment Percentage:</label>
          <input
            type='number'
            value={downPaymentPercentage}
            onChange={handleDownPaymentPercentageChange}
          />
          <p>{formatAsCurrency(downPaymentAmount)} cash downpayment</p>
          {/* onChange={(e) => setDownPaymentPercentage(e.target.value)} */}
        </div>
        <div className='input-group'>
          <label>Annual Interest Rate (%):</label>
          <input
            type='number'
            value={annualInterestRate}
            onChange={(e) => setAnnualInterestRate(e.target.value)}
          />
        </div>
        <div className='input-group'>
          <label>Loan Term (years):</label>
          <input
            type='number'
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
          />
        </div>
        <div className='input-group'>
          <label>Principal:</label>
          <span>
            {monthlyPayment !== 0 && <span>{formatAsCurrency(principal)}</span>}
          </span>
        </div>
        <div className='input-group'>
          <label>Monthly Payment:</label>

          {monthlyPayment !== 0 && (
            <span>{formatAsCurrency(monthlyPayment)}</span>
          )}
        </div>
        <Button
          text='Calculate'
          txtColor='black'
          backgroundColor={randomColor}
          onClick={calculateMonthlyPayment}
        />
      </div>
    </div>
  );
};

export default MortgageCalculator;
