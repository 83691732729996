import Course from '../../components/Course/Course.js';
import './services.scss';
import { useState } from 'react';
import help from '../../../images/help0.png';

const Services = ({ randomColor, journeyImage }) => {
  const [courses, setCourses] = useState([
    {
      id: 2,
      title: 'Basic Computer Understanding',
      info: 'This is a <b>2 week course</b> that will get you up to speed on how to operate and feel very comfortable sitting infront of a computer. This is great for older and younger students.',
      price: '$399',
      selected: false,
      buy: 'https://buy.stripe.com/3cs9Db83j0UA5mUfYY',
    },
    {
      id: 1,
      title:
        '<span class="badge-new">Best Seller</span> Complete Web Dev Course',
      info: 'This is a <b>6 month course</b> that will convert you from a student into an elite front end developer. We <b>guarantee</b> you a job upon completion.<br/><br/>Prerequisite: We expect you to know how to use a computer.',
      price: '$7200 or $600 per month for 12 months',
      selected: false,
      buy: 'https://buy.stripe.com/3cs9Db83j0UA5mUfYY',
    },
    {
      id: 3,
      title: 'Interview Preperation',
      info: 'This is a course where we teach you how to respond to any question in your interview process no matter the industry.',
      price: '$200 one time',
      selected: false,
      buy: 'https://buy.stripe.com/3cs9Db83j0UA5mUfYY',
    },
  ]);

  const [helpClicked, setHelpClicked] = useState(false);

  const courseClicked = (selectedCourse) => {
    const updatedCourses = courses.map((course) => ({
      ...course,
      selected: course.id === selectedCourse.id,
    }));

    setCourses(updatedCourses);
  };

  const helpBtnClicked = () => {
    setHelpClicked(!helpClicked);
  };

  return (
    <div className='services'>
      <h2>Enrollment &amp; Course Selection </h2>

      <div className='services header'>
        <h1>
          Your{' '}
          <span
            style={{
              borderRadius: '6px',
              padding: '15px',
              backgroundColor: randomColor,
              fontWeight: 900,
              transitionDuration: '0.4s',
            }}
          >
            Journey
          </span>{' '}
          with us, Starts Here.
        </h1>
        <p>
          This proven journey will{' '}
          <span
            style={{
              borderBottom: '10px solid',
              borderBottomColor: randomColor,
            }}
          >
            prepare
          </span>{' '}
          you for the real world and landing you, your very first engineering
          job.
        </p>
        <img
          src={journeyImage}
          alt='logo'
          style={{ maxWidth: '80%', height: 'auto' }}
        />
      </div>
      <div className='services header'>
        <h1>
          Course. <span className='light-text'>Which is best for you?</span>
        </h1>
      </div>
      <div className='flex-row'>
        {courses.map((course) => (
          <div
            className={`column ${course.selected ? 'selected' : ''}`}
            key={course.id}
          >
            <Course course={course} courseClicked={courseClicked} />
          </div>
        ))}
      </div>
      <div className='services header'>
        <h1>
          Checkout.{' '}
          <span className='light-text'>Which payment plan works for you?</span>
        </h1>
      </div>
      <div className='services center'>
        {courses[0].selected && (
          <div>
            <stripe-buy-button
              buy-button-id='buy_btn_1Nta78FvFqwoEbYQDXYB6muQ'
              publishable-key='pk_live_k7urevvHuMhtzw99ewzNJVGT00kvdgtcNv'
            ></stripe-buy-button>
          </div>
        )}
        {courses[1].selected && (
          <div>
            <stripe-buy-button
              buy-button-id='buy_btn_1NtbWcFvFqwoEbYQLncMmJc3'
              publishable-key='pk_live_k7urevvHuMhtzw99ewzNJVGT00kvdgtcNv'
            ></stripe-buy-button>
            <stripe-buy-button
              buy-button-id='buy_btn_1NtbcjFvFqwoEbYQNZUsWBSf'
              publishable-key='pk_live_k7urevvHuMhtzw99ewzNJVGT00kvdgtcNv'
            ></stripe-buy-button>
          </div>
        )}
        {courses[2].selected && (
          <div>
            <stripe-buy-button
              buy-button-id='buy_btn_1NtcB6FvFqwoEbYQQMxTx11A'
              publishable-key='pk_live_k7urevvHuMhtzw99ewzNJVGT00kvdgtcNv'
            ></stripe-buy-button>
          </div>
        )}
      </div>

      {!helpClicked && (
        <div className='services help-block' onClick={helpBtnClicked}>
          <div className='services img-container'>
            <img src={help} height='324' width='576' alt='James smiling' />
          </div>
          <div className='services help-message'>
            <p>
              <b>Not sure which course to select?</b>
            </p>
            <p>
              Get a better understanding of what course is suited for <b>you</b>{' '}
              by clicking on this video.
            </p>
          </div>
        </div>
      )}
      {helpClicked && (
        <div className='services help-video-container'>
          <iframe
            width='560'
            height='315'
            style={{ borderRadius: '15px' }}
            src='https://www.youtube.com/embed/gVlKn1vEkio?si=trHJKyzEio7d4KRo&autoplay=1'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          ></iframe>

          <div
            className='services pointer help-btn'
            onClick={helpBtnClicked}
            title='close the video player'
          >
            <span className='services aap-base__icon'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                <path
                  id='_art'
                  className='st1'
                  d='M25.5,16.5l-5.9,0l0-5.9C19.6,9.7,18.9,9,18,9c-0.9,0-1.5,0.7-1.5,1.5l0,5.9l-5.9,0h0
                  	                              C9.7,16.4,9,17.1,9,18c0,0.9,0.7,1.5,1.5,1.5l5.9,0l0,5.9c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5l0-5.9l5.9,0h0
                  	                              c0.9,0,1.5-0.7,1.5-1.5C27,17.2,26.3,16.5,25.5,16.5L25.5,16.5z'
                ></path>
              </svg>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
