import './WhyUs.scss';
import PropTypes from 'prop-types';

const WhyUs = ({ color }) => {
  const yes = `Career guidance and placement assistance. <span style="background-color: ${color};padding: 5px; border-radius: 6px;font-weight:900;transition-duration: 0.4s;">Yes</span>, once you complete our program, we will also guide you to get your first coding Job.`;

  const whyReasons = [
    'Real-world app projects for practical, hands on experience',
    'Your professors are Expert faculty with industry experience',
    'We will teach you Cutting-edge FrontEnd technologies like JavaScript, Angular & React.JS',
    'Experience using IDE (Integrated Development Enviroments) like VS Code',
    yes,
    'Networking opportunities with tech professionals',
    'Innovative learning environment',
  ];

  const valueReasons = [
    '<b>360 Degree View</b>: By providing a 360-degree view, students gain a holistic understanding of web development, covering not only technical aspects but also design, user experience, resume preperation, interview readiness, interview etiquette  and more. This well-rounded education prepares you to become a versatile professional.',
    '<b>Step-by-Step Guidance</b>: We walk students through each step of becoming a web developer, which shows a commitment to your success. You receive personalized attention and mentorship, making you feel supported and confident in your learning journey.',
    '<b>Relevance to Industry Needs</b>: Your curriculum is aligned with industry demands, ensuring that students graduate with skills that are immediately relevant and in demand. This can lead to better job prospects and a smoother transition into the workforce.',
    '<b>Rapid Skill Development</b>: The hands-on approach accelerates skill development. Students learn by doing, which will lead to faster mastery of concepts and technologies compared to traditional lecture-based learning.',
  ];

  return (
    <div className='why-us'>
      <div className='row'>
        <div className='why-column'>
          <h2>
            Why Choose{' '}
            <span
              style={{
                borderRadius: '6px',
                padding: '15px',
                backgroundColor: color,
                fontWeight: 900,
                transitionDuration: '0.4s',
              }}
            >
              Our Real World
            </span>{' '}
            University?
          </h2>
          <ul className='reasons-list'>
            {valueReasons.map((reason, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: reason }}></li>
            ))}
          </ul>
        </div>
        <div className='benefit-column'>
          <h2>
            <span
              style={{
                borderRadius: '6px',
                padding: '15px',
                backgroundColor: color,
                fontWeight: 900,
                transitionDuration: '0.4s',
              }}
            >
              What do I get when joining
            </span>{' '}
            Rolonium University?
          </h2>
          <ul className='reasons-list'>
            {whyReasons.map((reason, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: reason }}></li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

WhyUs.defaultProps = {
  color: 'steelblue',
};

WhyUs.propTypes = {
  color: PropTypes.string,
};

export default WhyUs;
