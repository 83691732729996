import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Apps = ({ color }) => {
  return (
    <div className='why-us'>
      <h2>Apps your Professor James has built</h2>

      <Link to='/mcalc'>
        <p>Mortgage Calculator</p>
      </Link>
      <p>coming soon</p>
      <p>coming soon</p>
      {/* <p>Property Management App</p>
      <p>Property Income Calculator</p> */}
    </div>
  );
};

Apps.defaultProps = {
  color: 'steelblue',
};

Apps.propTypes = {
  color: PropTypes.string,
};

export default Apps;
