import './Course.scss';

const Course = ({ course, courseClicked }) => {
  return (
    <div className={course} onClick={() => courseClicked(course)}>
      <h3 dangerouslySetInnerHTML={{ __html: course.title }}></h3>

      <p dangerouslySetInnerHTML={{ __html: course.info }}></p>
      <small className='floatRight'>{course.price}</small>
    </div>
  );
};

export default Course;
