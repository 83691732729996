import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faInstagram,
//   faLinkedin,
//   faTwitter,
// } from '@fortawesome/free-solid-svg-icons';

// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import rl from '../../../images/ru.png';

const Footer = ({ color }) => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        <div
          className='footer-column'
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Link to='/'>
            <img
              src={rl}
              alt='RoloniumUniversity'
              width='103'
              sizes='(max-width: 479px) 100vw, (max-width: 1919px) 216.9965362548828px, 167.98611450195312px'
              style={{ marginLeft: '10px' }}
            />
          </Link>
        </div>
        <div className='footer-column'>
          <h3>Services</h3>
          <ul>
            <li>
              <Link to='/services'>Enrollment &amp; Courses</Link>
            </li>
            <li>
              <Link to='/contact'>Download Free Web Dev PDF</Link>
            </li>
          </ul>
        </div>
        <div className='footer-column'>
          <h3>Stay Connected with Us</h3>
          <ul>
            {/* <a href='https://www.instagram.com/your_instagram_profile/'> */}
            {/* </a> */}
            {/* <li>
              <a href='https://www.linkedin.com/in/your_linkedin_profile/'>
                <FontAwesomeIcon icon={faCoffee} size='2x' />
              </a>
            </li>
            <li>
              <a href='https://twitter.com/your_twitter_profile/'>
                <FontAwesomeIcon icon={faCoffee} size='2x' />
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div
        className='footer-bottom'
        style={{ backgroundColor: 'rgb(124, 157, 150)' }}
      >
        <p>
          &copy; {new Date().getFullYear()} RoloniumUniversity. All Rights
          Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
