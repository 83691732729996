import PropTypes from 'prop-types';
import { useState } from 'react';

const Button = ({ backgroundColor, text, txtColor, onClick, className }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const buttonStyle = {
    color: txtColor,
    opacity: hovered ? 0.7 : 1,
    backgroundColor: backgroundColor,
  };

  return (
    <button
      onClick={onClick}
      className={`btn ${className !== '' ? className : ''}`}
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </button>
  );
};

Button.defaultProps = {
  color: 'steelblue',
  className: '',
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Button;
