import React, { useState, useEffect } from 'react';
import './nav.css';
import rl from '../../../images/2.png';
import { Link } from 'react-router-dom';

const NavHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
        <div className='navbar-content'>
          <div>
            <img
              src={rl}
              alt='RoloniumUniversity'
              width='203'
              sizes='(max-width: 479px) 100vw, (max-width: 1919px) 216.9965362548828px, 167.98611450195312px'
              style={{ marginLeft: '10px' }}
            />
          </div>
          <div className='links'>
            <Link to='/apps'>
              <p>Apps</p>
            </Link>
          </div>
          {/* <div className='links'>
            <a href='#home'>Home</a>
            <a href='#about'>About</a>
            <a href='#services'>Services</a>
            <a href='#contact'>Contact</a>
          </div> */}
        </div>
      </nav>

      {/* <div className='nav'>
        <div>
          <img
            src={rl}
            alt='RoloniumUniversity'
            width='103'
            sizes='(max-width: 479px) 100vw, (max-width: 1919px) 216.9965362548828px, 167.98611450195312px'
            style={{ marginLeft: '10px' }}
          />
        </div>
        <div className='links'>
          <p>Why Us</p>
          <p>Pricing</p>
          <p>JOIN NOW</p>
        </div>
      </div> */}
    </div>
  );
};

export default NavHeader;
